import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Inline from "../../../../../components/inline"
import Score from "../../../../../components/score"
import CourseFooterNext from "../../../../../components/course-footer-next"
import FeedbackHeaderProgress from "../../../../../components/feedback-header-progress"
import SolutionButton from "../../../../../components/solution-button"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"

const Page = () => {
  const { answerStatusForArrayValue, getStatsForTaskWithArray } =
    useCourseStore()
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })
  const task = {
    courseId: "reiseerinnerung",
    chapterId: "01-suedfrankreich",
    taskId: "reiseformen",
  }
  const statistics = getStatsForTaskWithArray(task)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext
          title="Alles klar soweit?"
          label="Weiter"
          to="/kurse/reiseerinnerung/01-suedfrankreich/reiseformen/zusammenfassung"
        />
      }
    >
      <Seo title="Reiseformen im Gedicht" />
      <Stack>
        <FeedbackHeaderProgress
          variant="solution"
          correct={statistics.correct}
          total={statistics.correct + statistics.missed}
        />
        <Inline alignY="center">
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "Seefahrt" })}
          >
            Schiff -/ Seefahrt
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "Wanderung" })}
          >
            Wanderung
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "Bergbesteigung",
            })}
          >
            Bergbesteigung
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "Kutschfahrt",
            })}
          >
            Kutschfahrt
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "Gedankenreise",
            })}
          >
            Gedankenreise
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "Bahnfahrt" })}
          >
            Bahnfahrt
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "Zeitreise" })}
          >
            Zeitreise
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "Städte-Reise",
            })}
          >
            Städte-Reise
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "Pilgerreise",
            })}
          >
            Pilgerreise
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "Expedition" })}
          >
            Expedition
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "Reiseerinnerung",
            })}
          >
            Reiseerinnerung
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "Strandurlaub",
            })}
          >
            Strandurlaub
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({ ...task, value: "Flugreise" })}
          >
            Flugreise
          </SolutionButton>
          <SolutionButton
            status={answerStatusForArrayValue({
              ...task,
              value: "Bildungsreise",
            })}
          >
            Bildungsreise
          </SolutionButton>
        </Inline>
        <Score statistics={statistics} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
